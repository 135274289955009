@import "primeng/resources/primeng.css";
// Vex Core
@import "./@vex/styles/core";

:root {
  .p-tooltip {
    background: #666666;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    padding: 5px 10px;
    line-height: 1;
  }

  .p-tooltip-bottom {
    margin-top: 5px;
  }
}
